export default {
  error: {
    UNKNOWN_ERROR: 'Unknown error. If the problem persists, please contact support',
    INVALID_INPUTS: 'The data sent in the form is invalid',
    EMAIL_ALREADY_USED: 'Email already used',
    INVALID_CREDENTIALS: 'Your email or password is incorrect. Make sure you have signed up or entered the correct credentials',
    ACCOUNT_NOT_CONFIRMED: 'You must confirm your account before you can log in',
    INVALID_CODE: 'Invalid code',
    INVALID_TOKEN: 'The Token supplied is invalid',
    INVALID_PASSWORD: 'Password invalid',
    INVALID_OLD_PASSWORD: 'Current password is invalid',
    INVALID_AUTHORIZATION_TOKEN: 'The authorisation token is invalid',
    APPLICATION_NOT_FOUND: 'Application not found',
    INVALID_SECRET: 'Invalid secret',
    NICKNAME_ALEREADY_USED: 'Identifier already in use',
  },
};
