import { yupResolver } from '@hookform/resolvers/yup';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { updateEmailSchema, updateEmailType } from '../../schema/DashboardSchema';
import { DashboardButton } from '../../components/common/Button';
import { InputWord } from '../../components/common/Input';
import UsersService from '../../services/users.service';
import AuthSettingsItemHeader from '../../components/common/AuthSettingsItemHeader';
import { ToastQueueRefContext } from '../../components/common/Toast';

const DashboardAuthenticationEmailComponent = () => {
  const navigate = useNavigate();
  const usersService = new UsersService();
  const toastQueueRef = useContext(ToastQueueRefContext);

  const updateEmailMethods = useForm<updateEmailType>({
    resolver: yupResolver(updateEmailSchema),
    mode: 'onSubmit',
    defaultValues: {
      newEmail: '',
      confirmNewEmail: '',
    },
  });

  const confirmUpdateEmail = async ({ confirmNewEmail }: updateEmailType) => {
    await usersService.updateEmail({
      email: confirmNewEmail,
    });

    toastQueueRef?.current?.addToast({
      type: 'SUCCESS',
      description: 'Email mis à jour',
      timer: 5000,
    });

    setTimeout(() => {
      navigate('/dashboard/authentication');
    }, 5000);
  };

  return (
    <>
      <AuthSettingsItemHeader title='Modifier votre adresse e-mail' description='Modifier votre adresse e-mail de connexion' />
      <FormProvider {...updateEmailMethods}>
        <form onSubmit={updateEmailMethods.handleSubmit(confirmUpdateEmail)}>
          <div className='w-1/2 max-md:w-full'>
            <Controller
              name='newEmail'
              control={updateEmailMethods.control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <InputWord
                    label='Nouvelle adresse e-mail'
                    type='text'
                    placeholder='exemple@email.com'
                    value={value}
                    onChangeValue={onChange}
                    errorMessage={error?.message}
                  />
                </>
              )}
            />
          </div>
          <div className='w-1/2 max-md:w-full'>
            <Controller
              name='confirmNewEmail'
              control={updateEmailMethods.control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <InputWord
                    label='Confirmer la nouvelle adresse e-mail'
                    type='text'
                    placeholder='exemple@email.com'
                    value={value}
                    onChangeValue={onChange}
                    errorMessage={error?.message}
                  />
                </>
              )}
            />
          </div>
          <div className='mt-6 flex justify-end'>
            <DashboardButton type='cancel' onClick={() => navigate('/dashboard/authentication')} />
            <DashboardButton type='confirm' />
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default DashboardAuthenticationEmailComponent;
