import React, { useState } from 'react';
import FormHeader from '../../../components/forms/FormHeader';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { InputWord } from '../../../components/common/Input';
import { PasswordConstraint } from '../../../components/common/PasswordConstraint';
import { BasicButton } from '../../../components/common/Button';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { resetPasswordSchema, resetPasswordType } from '../../../schema/ResetPasswordSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import AuthService from '../../../services/auth.service';

type PasswordResetFormProps = {
  setPageStep: React.Dispatch<React.SetStateAction<string>>;
  resetPasswordToken: string | null;
  redirectUrl?: string | null;
};

const PasswordResetForm = ({ setPageStep, resetPasswordToken, redirectUrl }: PasswordResetFormProps) => {
  const navigate: NavigateFunction = useNavigate();
  const authService = new AuthService();

  const resetPasswordMethods = useForm<resetPasswordType>({
    resolver: yupResolver(resetPasswordSchema),
    mode: 'onSubmit',
    defaultValues: {
      newPassword: '',
      confirmNewPassword: '',
    },
  });

  const [passwordFocus, setPasswordFocus] = useState<boolean>(false);

  const resetPassword = async ({ newPassword }: resetPasswordType) => {
    if (resetPasswordToken) {
      await authService.resetPasswordConfirm(resetPasswordToken, newPassword);
      setPageStep(`resetPasswordSuccess`);
    }
  };

  return (
    <>
      <FormHeader
        title='Réinitialiser votre mot de passe'
        subTitle='Votre adresse e-mail a été vérifiée. Vous pouvez maintenant procéder à la réinitialisation de votre mot de passe.'
      />
      <FormProvider {...resetPasswordMethods}>
        <form onSubmit={resetPasswordMethods.handleSubmit(resetPassword)}>
          <Controller
            name='newPassword'
            control={resetPasswordMethods.control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <>
                <InputWord
                  label='Mot de passe'
                  type='password'
                  placeholder='Mot de passe'
                  value={value}
                  onChangeValue={onChange}
                  errorMessage={error?.message}
                  onFocus={() => {
                    setPasswordFocus(true);
                  }}
                  onBlur={() => {
                    setPasswordFocus(false);
                    onBlur();
                  }}
                />
              </>
            )}
          />
          {passwordFocus && <PasswordConstraint password={resetPasswordMethods.watch('newPassword')} />}
          <Controller
            name='confirmNewPassword'
            control={resetPasswordMethods.control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <InputWord
                  label='Confirmer votre mot de passe'
                  type='password'
                  placeholder='Mot de passe'
                  value={value}
                  onChangeValue={onChange}
                  errorMessage={error?.message}
                />
              </>
            )}
          />
          <BasicButton label='Réinitialiser mon mot de passe' fullWidth={true} isSubmitButton={true} />
          <div
            onClick={() => navigate(redirectUrl ? `/login?redirectUrl=${redirectUrl}` : `/login`)}
            className='mt-3 cursor-pointer text-center font-bold text-green-secondary hover:underline'
          >
            Retour à la page de connexion
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default PasswordResetForm;
