import { apiRequest, apiSkeziaRequest } from '../fetcher';
import { AppCreated, Application, EditedApp, Project, Scopes } from './types';

export default class ApiConnectionsService {
  async createApplication(name: string, projectId: string): Promise<AppCreated> {
    const { data } = await apiRequest.post(
      `/applications/create`,
      { name, skeziaProjectId: projectId, scopes: [] },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      },
    );

    return data;
  }

  async fetchApplications(): Promise<Application[]> {
    const { data } = await apiRequest.get(`/applications`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
    });

    return data;
  }

  async deleteApplication(appId: string): Promise<Application[]> {
    const { data } = await apiRequest.delete(`/applications/${appId}/delete`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
    });

    return data;
  }

  async fetchProjects(): Promise<Project[]> {
    const { data } = await apiSkeziaRequest.get('/projects', {
      params: { owner: true },
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
    });

    return data;
  }

  async updateSecret(appId: string): Promise<{ appSecret: string }> {
    const { data } = await apiRequest.patch(`/applications/${appId}/update-secret`, undefined, {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
    });

    return data;
  }

  async updateApplication(appId: string, editedApp: EditedApp): Promise<{ appId: string; name: string }> {
    const { data } = await apiRequest.patch(`/applications/${appId}/update`, editedApp, {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
    });

    return data;
  }

  async fetchScopes(): Promise<Scopes> {
    const { data } = await apiRequest.get(`/applications/scopes`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
    });

    return data;
  }
}
