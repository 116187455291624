import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from './locales/en';
import fr from './locales/fr';

export default i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['navigator'],
      caches: [],
    },
    resources: {
      en: { translation: { ...en } },
      fr: { translation: { ...fr } },
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export const t = i18n.t;
export const tExists = i18n.exists;
export const tIfExists = (key: string, defaultValue?: string) => (i18n.exists(key) ? t(key) : (defaultValue ?? key));
export const locale = i18n.language.split('-')[0];
