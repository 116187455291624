import { FC, useLayoutEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import UsersService from '../../services/users.service';

export const PrivateRoute: FC = () => {
  const usersService = new UsersService();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    usersService.getUserData().catch(() => navigate('/logout', { replace: true }));
  });

  return <Outlet />;
};
