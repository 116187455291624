import { useState } from 'react';
import FormHeader from '../../../components/forms/FormHeader';
import { FormProvider, Controller, useForm, UseFormReturn } from 'react-hook-form';
import { InputWord } from '../../../components/common/Input';
import { yupResolver } from '@hookform/resolvers/yup';
import { updateSkemeetPasswordSchema, updateSkemeetPasswordType } from '../../../schema/LoginSchema';
import AuthService from '../../../services/auth.service';
import { PasswordConstraint } from '../../../components/common/PasswordConstraint';
import { BasicButton } from '../../../components/common/Button';

type UpdateSkemeetPasswordFormProps = {
  setTokenAndRedirect: (accessToken: string, refreshToken: string) => void;
  connexionMethods: UseFormReturn<{ nickNameOrEmail: string; password: string }, undefined>;
  skemeetUserInfos: { temporaryAccessToken: string; firstname: string; lastname: string; cellPhone: string };
};

const UpdateSkemeetPasswordForm = ({ setTokenAndRedirect, connexionMethods, skemeetUserInfos }: UpdateSkemeetPasswordFormProps) => {
  const authService = new AuthService();

  const updateSkemeetPasswordMethods = useForm<updateSkemeetPasswordType>({
    resolver: yupResolver(updateSkemeetPasswordSchema),
    mode: 'onSubmit',
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  const [passwordFocus, setPasswordFocus] = useState<boolean>(false);

  const updateSkemeetPassword = async ({ password }: updateSkemeetPasswordType) => {
    const loginReturn = await authService.createUserFromSkemeetAndLogin({
      email: connexionMethods.watch('nickNameOrEmail'),
      password,
      cellPhone: skemeetUserInfos.cellPhone,
      firstname: skemeetUserInfos.firstname,
      lastname: skemeetUserInfos.lastname,
    });

    setTokenAndRedirect(loginReturn.accessToken, loginReturn.refreshToken);
  };

  return (
    <>
      <FormHeader
        title={`Mise à jour de votre mot de passe`}
        subTitle={`Pour des raisons de sécurité, nous vous demandons de renforcer votre mot de passe afin qu'il contienne : 12 caractères, une lettre majuscule et minuscule, un chiffre et un caractère spécial.`}
      />
      <FormProvider {...updateSkemeetPasswordMethods}>
        <form onSubmit={updateSkemeetPasswordMethods.handleSubmit(updateSkemeetPassword)}>
          <Controller
            name='password'
            control={updateSkemeetPasswordMethods.control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <>
                <InputWord
                  label='Nouveau mot de passe'
                  type='password'
                  mandatory={true}
                  placeholder='Mot de passe'
                  value={value}
                  onChangeValue={onChange}
                  errorMessage={error?.message}
                  onFocus={() => {
                    setPasswordFocus(true);
                  }}
                  onBlur={() => {
                    setPasswordFocus(false);
                    onBlur();
                  }}
                />
              </>
            )}
          />
          {passwordFocus && <PasswordConstraint password={updateSkemeetPasswordMethods.watch('password')} />}
          <Controller
            name='confirmPassword'
            control={updateSkemeetPasswordMethods.control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <InputWord
                  label='Confirmer votre mot de passe'
                  type='password'
                  placeholder='Mot de passe'
                  value={value}
                  onChangeValue={onChange}
                  errorMessage={error?.message}
                />
              </>
            )}
          />
          <BasicButton label='Mettre à jour mon mot de passe' fullWidth={true} isSubmitButton={true} />
          <div
            onClick={() => setTokenAndRedirect(skemeetUserInfos.temporaryAccessToken, '')}
            className='mt-3 cursor-pointer text-center font-bold text-green-secondary hover:underline'
          >
            Me le rappeler plus tard
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default UpdateSkemeetPasswordForm;
