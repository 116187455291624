import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { doubleAuthenticationSchema, doubleAuthenticationType } from '../../schema/DashboardSchema';
import UsersService from '../../services/users.service';
import { DashboardButton } from '../../components/common/Button';
import AuthSettingsItemHeader from '../../components/common/AuthSettingsItemHeader';
import { InputCheckbox } from '../../components/common/Input';
import { ToastQueueRefContext } from '../../components/common/Toast';

const DashboardAuthenticationDoubleAuthenticationComponent = () => {
  const navigate = useNavigate();
  const usersService = new UsersService();
  const toastQueueRef = useContext(ToastQueueRefContext);

  const doubleAuthenticationMethods = useForm<doubleAuthenticationType>({
    resolver: yupResolver(doubleAuthenticationSchema),
    mode: 'onSubmit',
    defaultValues: {
      activeSmsAuthentication: false,
      cellphone: '',
    },
  });

  const updateOTP = async ({ activeSmsAuthentication, cellphone }: doubleAuthenticationType) => {
    await usersService.updateDoubleAuthentication(activeSmsAuthentication, cellphone).then(() =>
      toastQueueRef?.current?.addToast({
        type: 'SUCCESS',
        description: 'Authentification à double facteur mise à jour',
        timer: 5000,
      }),
    );

    navigate('/dashboard/authentication');
  };

  useEffect(() => {
    (async () => {
      const user = await usersService.getUserData();
      doubleAuthenticationMethods.setValue('activeSmsAuthentication', user.oneTimePasswordActivated ?? '');
      doubleAuthenticationMethods.setValue('cellphone', user.cellphone);
    })();
  }, []);

  return (
    <FormProvider {...doubleAuthenticationMethods}>
      <form onSubmit={doubleAuthenticationMethods.handleSubmit(updateOTP)}>
        <AuthSettingsItemHeader title='Protéger votre compte avec l’authentification à double facteur' description='' />
        <Controller
          name='activeSmsAuthentication'
          control={doubleAuthenticationMethods.control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <InputCheckbox
              label={<span className='font-bold text-[#21577A]'>Activer l’authentification à double facteur par SMS</span>}
              checked={value}
              color='#F6A436'
              actionOnClick={() => onChange(!value)}
              errorMessage={error?.message}
            />
          )}
        />
        <div className='mt-4 text-[#21577A]'>
          Recevez un code par SMS à chaque connexion afin de renforcer la sécurité de votre compte.
          <br />
          Ce code permet de s’assurer que vous êtes bien la personne qui souhaite se connecter.
        </div>
        <div className='mt-4 w-1/2 max-md:w-full'>
          <div className='flex w-full'>
            <div className='w-1/4 font-bold text-[#21577A]'>Pays</div>
            <div className='w-3/4 font-bold text-[#21577A]'>Numéro de mobile</div>
          </div>
          <div className='mt-2 w-full'>
            <button type='button' className='w-1/4 rounded-l-lg border border-[#DCE2EC] bg-[#F6F8FF] py-4 pl-2 text-left text-[#9CABCB]'>
              +33
            </button>
            <Controller
              name='cellphone'
              control={doubleAuthenticationMethods.control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <input
                    type='text'
                    maxLength={10}
                    className='w-3/4 rounded-r-lg border border-[#DCE2EC] bg-[#F6F8FF] py-4 pl-2'
                    placeholder='00 00 00 00 00'
                    onChange={onChange}
                    value={value}
                  />
                  <div className='pt-2 font-medium text-red-primary'>{error?.message}</div>
                </>
              )}
            />
          </div>
        </div>
        <div className='mt-6 flex justify-end'>
          <DashboardButton type='cancel' onClick={() => navigate('/dashboard/authentication')} />
          <DashboardButton type='confirm' />
        </div>
      </form>
    </FormProvider>
  );
};

export default DashboardAuthenticationDoubleAuthenticationComponent;
