import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Seo } from '../../components/common/Seo';
import PasswordRequestForm from './forms/PasswordRequestForm';
import PasswordRequestSent from './PasswordRequestSent';
import PasswordResetForm from './forms/PasswordResetForm';
import PasswordResetSuccess from './PasswordResetSuccess';
import ErrorForm from '../../components/forms/ErrorForm';

const ResetPasswordComponent = () => {
  const navigate = useNavigate();
  const searchQueryParams = useSearchParams()[0];
  const resetPasswordToken = searchQueryParams.get('resetPasswordToken');
  const [pageStep, setPageStep] = useState(resetPasswordToken ? 'emailVerified' : 'request');
  const redirectUrl = searchQueryParams.get('redirectUrl');

  return (
    <>
      <div className={`z-[10] w-full rounded-lg bg-white p-6`}>
        {
          {
            request: <PasswordRequestForm setPageStep={setPageStep} redirectUrl={redirectUrl} />,
            emailSend: <PasswordRequestSent />,
            emailVerified: (
              <PasswordResetForm setPageStep={setPageStep} resetPasswordToken={resetPasswordToken} redirectUrl={redirectUrl} />
            ),
            resetPasswordSuccess: <PasswordResetSuccess redirectUrl={redirectUrl} />,
            error: <ErrorForm onClick={() => navigate(redirectUrl ? `/login?redirectUrl=${redirectUrl}` : `/login`)} />,
          }[pageStep]
        }
      </div>
      <Seo title={'Réinitialiser votre mot de passe'} />
    </>
  );
};

export default ResetPasswordComponent;
