import React from 'react';
import FormHeader from '../../../components/forms/FormHeader';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { InputWord } from '../../../components/common/Input';
import { BasicButton } from '../../../components/common/Button';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { resetNicknameSchema, resetNicknameType } from '../../../schema/ResetNicknameSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import AuthService from '../../../services/auth.service';

type NicknameResetFormProps = {
  setPageStep: React.Dispatch<React.SetStateAction<string>>;
  resetNicknameToken: string | null;
  redirectUrl?: string | null;
};

const NicknameResetForm = ({ setPageStep, resetNicknameToken, redirectUrl }: NicknameResetFormProps) => {
  const navigate: NavigateFunction = useNavigate();
  const authService = new AuthService();

  const resetNicknameMethods = useForm<resetNicknameType>({
    resolver: yupResolver(resetNicknameSchema),
    mode: 'onSubmit',
    defaultValues: {
      nickname: '',
    },
  });

  const resetNickname = async ({ nickname }: resetNicknameType) => {
    if (resetNicknameToken) {
      await authService.resetNicknameConfirm(resetNicknameToken, nickname);
      setPageStep(`resetNicknameSuccess`);
    }
  };

  return (
    <>
      <FormHeader
        title='Réinitialiser votre identifiant'
        subTitle='Votre adresse e-mail a été vérifiée. Vous pouvez maintenant procéder à la réinitialisation de votre identifiant.'
      />
      <FormProvider {...resetNicknameMethods}>
        <form onSubmit={resetNicknameMethods.handleSubmit(resetNickname)}>
          <Controller
            name='nickname'
            control={resetNicknameMethods.control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <InputWord
                  label='Identifiant'
                  type='text'
                  mandatory={true}
                  placeholder='Identifiant'
                  value={value}
                  onChangeValue={onChange}
                  errorMessage={error?.message}
                />
              </>
            )}
          />
          <BasicButton label='Réinitialiser mon identifiant' fullWidth={true} isSubmitButton={true} />
          <div
            onClick={() => navigate(redirectUrl ? `/login?redirectUrl=${redirectUrl}` : `/login`)}
            className='mt-3 cursor-pointer text-center font-bold text-green-secondary hover:underline'
          >
            Retour à la page de connexion
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default NicknameResetForm;
