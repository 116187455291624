import { yupResolver } from '@hookform/resolvers/yup';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { nicknameSchema, nicknameType } from '../../schema/DashboardSchema';
import UsersService from '../../services/users.service';
import { InputWord } from '../../components/common/Input';
import { DashboardButton } from '../../components/common/Button';
import AuthSettingsItemHeader from '../../components/common/AuthSettingsItemHeader';
import { ToastQueueRefContext } from '../../components/common/Toast';

const DashboardAuthenticationNickNameComponent = () => {
  const navigate = useNavigate();
  const usersService = new UsersService();
  const toastQueueRef = useContext(ToastQueueRefContext);

  const nicknameMethods = useForm<nicknameType>({
    resolver: yupResolver(nicknameSchema),
    mode: 'onSubmit',
    defaultValues: {
      nickName: '',
    },
  });

  const updateNickname = async ({ nickName }: nicknameType) => {
    await usersService.updateNickname({
      nickName,
    });

    toastQueueRef?.current?.addToast({
      type: 'SUCCESS',
      description: 'Identifiant mise à jour',
      timer: 5000,
    });

    navigate('/dashboard/authentication');
  };

  return (
    <>
      <AuthSettingsItemHeader
        title='Modifier votre identifiant'
        description='Nous vous recommandons de choisir un identifiant personnel ne pouvant pas être deviné : évitez « nom.prénom »'
      />
      <FormProvider {...nicknameMethods}>
        <form onSubmit={nicknameMethods.handleSubmit(updateNickname)}>
          <div className='w-1/2 max-md:w-full'>
            <Controller
              name='nickName'
              control={nicknameMethods.control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <InputWord
                    label='Identifiant'
                    type='text'
                    placeholder='ex : SocrateHelenien8'
                    value={value}
                    onChangeValue={onChange}
                    errorMessage={error?.message}
                  />
                </>
              )}
            />
          </div>
          <div className='mt-6 flex justify-end'>
            <DashboardButton type='cancel' onClick={() => navigate('/dashboard/authentication')} />
            <DashboardButton type='confirm' />
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default DashboardAuthenticationNickNameComponent;
