import axios from 'axios';

export const apiRequest = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
});

export const apiSkeziaRequest = axios.create({
  baseURL: import.meta.env.VITE_SKEZIA_BACK_URL,
});

export const refreshToken = async () => {
  try {
    const { data } = await axios.post(`/auth/regenerate-token`, undefined, {
      baseURL: import.meta.env.VITE_API_URL,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('refreshToken')}`,
      },
    });

    localStorage.setItem('accessToken', data.accessToken);
    localStorage.setItem('refreshToken', data.refreshToken);

    return Promise.resolve(data.accessToken);
  } catch (error) {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    return Promise.reject(error);
  }
};
